import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Wrapper from '../components/Wrapper'
import Hero from '../components/Hero'
import PostsList from '../components/PostsList'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import Search from "../components/search"
const searchIndices = [{ name: `twts`, title: `twts` }]

class BlogList extends React.Component {
  render() {
    const { title, description } = this.props.data.site.siteMetadata
    const posts = this.props.data.posts.edges
    const { pageContext } = this.props

    return (
      <Layout location={this.props.location}>
        <SEO />
        <Hero title={title} subTitle={description} />

        <Wrapper>
          <Search indices={searchIndices} />
          <PostsList posts={posts} />
        </Wrapper>

        <Pagination
          nbPages={pageContext.nbPages}
          currentPage={pageContext.currentPage}
        />
      </Layout>
    )
  }
}

export default BlogList

export const pageQuery = graphql`
query blogListQuery($skip: Int!, $limit: Int!) {
  site {
    siteMetadata {
      title
      description
    }
  }
  posts: allMysqlTweets(sort: {fields: [date], order: ASC}, limit: $limit, skip: $skip) {
    edges {
      node {
        text
        tags {
					tag
        }
        slug
      }
    }
  }
}
`
